import React, { useEffect, useState } from 'react'
import { View, Platform, StyleSheet, Keyboard, Modal, TouchableWithoutFeedback } from 'react-native'
import KeyboardSpacer from 'react-native-keyboard-spacer'
import {
  getConversationMessages,
  deleteConversationMessage,
  readAllMessages,
  createGroupConversation,
  getScreeningConsent,
  getConversationFeature,
} from '../../../services/api/Inbox'

import * as InboxService from '../../../services/api/Inbox'

import { Chat } from './chat/Chat'
import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import { unacceptedConversationText } from '../../../constants/BadWords'
import NormalIcon from '../../icons/NormalIcon'
import moment from 'moment'
import 'moment/min/locales' //needs to be there to update locale to another countrycode.
import { SpacingStyles } from '../../../styles'
import { Portal } from 'react-native-paper'
import OptionsDialog from '../../dialogs/OptionsDialog'
import OptionButton from '../../buttons/OptionButton'
import ScreeningConsentDialog from '../../dialogs/ScreeningConsentDialog'
import { useSelector } from 'react-redux'
import { conversationMessages } from '../../../redux/selectors'
import ConversationHeader from './ConversationHeader'
import Fontsizes from '../../../constants/Fontsizes'
import CustomDialog, { ContentContainer, ActionButton } from '../../dialogs/CustomDialog'
import { CustomText } from '../../text/StyledText'
import { ButtonPrimary } from '../../buttons/StyledButtons'
import { getConnection, isConnected } from '../../../services/api/ConnectionApi'
import Connection from './Connection'
import { Features } from '../../../constants/Features'
import * as Clipboard from 'expo-clipboard'
import CustomPressable from '../../pressables/CustomPressable'
import ContactUnavailableDialog from '../../dialogs/ContactUnavailableDialog'
import { handlePaymentClick } from "../../../utils/handlePaymentClick"

export default function Conversation(props) {
  moment.locale(txt('conversations.locale')) //Sets locale to chosen language code from Txt
  const [showConsentDialog, setShowConsentDialog] = useState(false)
  const [showMessageOptions, setShowMessageOptions] = useState(false)
  const [showCopyOption, setShowCopyOption] = useState(false)
  const [showConfirmDeletion, setShowConfirmDeletion] = useState(false)
  const [showTooManyConvDialog, setShowTooManyConvDialog] = useState(false)
  const [selectedMessage, setSelectedMessage] = useState('')
  const [showNoAccessDialog, setShowNoAccessDialog] = useState(false)
  const [textCopied, setTextCopied] = useState(false)
  const [messageSuspendedDialogVisible, setMessageSuspendedDialogVisible] = useState(false)
  const [messageSuspendedDialogText, setMessageSuspendedDialogText] = useState('')

  const [showTextValidateDialog, setShowTextValidateDialog] = useState(false)
  const [unacceptedText, setUnacceptedText] = useState('')

  const [connection, setConnection] = useState(false)
  const [hasFeature, setHasFeature] = useState(true)
  let keyboardDidHide

  const user = useSelector((state) => state.user)
  const conversation = useSelector((state) =>
    state.conversations.find((c) => c.conversation_id === parseInt(props.route.params.id))
  )
  const messages = useSelector((state) => conversationMessages(state, parseInt(props.route.params.id)))

  useEffect(() => {
    getConversationFeature(conversation?.conversation_id).then(setHasFeature)
    setShowNoAccessDialog(sendMessageNotAllowed())
  }, [user, props.route.params.id])

  useEffect(() => {
    if (props.route.params.id !== 'new') {
      getConversationMessages(parseInt(props.route.params.id))
      readAllMessages(parseInt(props.route.params.id))
    }
  }, [props.route.params.id, conversation?.messages?.length])

  useEffect(() => {
    props.navigation.setOptions({
      headerBackTitleVisible: false,
      headerTitle: () => (
        <ConversationHeader
          newConversation={props.route.params.id === 'new'}
          conversation={conversation}
          navigation={props.navigation}
          navigateToInfo={props.navigateToInfo}
        />
      ),
      headerRight: () => (
        <CustomPressable
          accessibilityRole={'button'}
          accessibilityLabel={txt('conversation.info')}
          onPress={props.navigateToInfo}
          style={{ paddingHorizontal: 12 }}
        >
          <NormalIcon
            name="ellipsis-h"
            color={Colors.tintColor}
            accessibilityLabel={txt('conversation.arrowIcon')}
            size={Fontsizes.l}
            stroke="far"
          />
        </CustomPressable>
      ),
      headerTitleContainerStyle: styles.title,
    })
  }, [props.route.params.id])

  useEffect(() => {
    function handleKeyboardDidHide() {
      if (unacceptedText !== '') {
        setShowTextValidateDialog(true)
      }
    }

    if (Platform.OS !== 'web') {
      keyboardDidHide = Keyboard.addListener('keyboardDidHide', handleKeyboardDidHide)
      return () => keyboardDidHide.remove()
    }
  }, [unacceptedText])

  useEffect(() => {
    if (conversation?.otherUsers?.length > 0) getConnection(conversation?.otherUsers[0].id).then(setConnection)
  }, [])

  const handleOnPressAvatar = async (item) => {
    const hasConnection = await isConnected(item._id)
    if (hasConnection) props.navigateToProfile(item._id)
  }

  const copyOption = {
    label: txt('conversations.messageOptionsCopy'),
    icon: "copy",
    action: async () => {
      setShowCopyOption(false)
      setShowMessageOptions(false)
      await copyText()
    },
  }

  const copyOptions = [copyOption]

  const options = [
    copyOption,
    {
      label: txt('conversations.messageDeleteConfirmDelete'),
      icon: "trash",
      action: () => {
        setShowMessageOptions(false)
        setShowConfirmDeletion(true)
      },
      type: 'danger',
    },
  ]

  const optionsConfirmDelete = [
    {
      label: txt('conversations.messageDeleteConfirmDelete'),
      icon: "trash",
      action: () => deleteMessage(selectedMessage),
      type: 'danger',
    },
    {
      label: txt('conversations.messageDeleteConfirmReturn'),
      action: () => setShowConfirmDeletion(false),
    },
  ]

  const onMessageLongPress = (context, message) => {
    if (message.status_id !== 1) return
    if (user.id === message.user_id && message.status_id === 1) {
      setShowMessageOptions(true)
    } else {
      setShowCopyOption(true)
    }
    setSelectedMessage(message)
  }

  const deleteMessage = async () => {
    setShowConfirmDeletion(false)
    if (selectedMessage.status_id === 1) {
      await deleteConversationMessage(parseInt(props.route.params.id), selectedMessage.id)
      getConversationMessages(parseInt(props.route.params.id))
    }
  }

  function setError() {
    setShowTooManyConvDialog(true)
    return null
  }

  async function createNewConversation() {
    let conversation
    const receiverIds = props.route.params.receiverIds
    if (receiverIds) conversation = await createGroupConversation([...receiverIds])
    else conversation = await props.createNewConversation()
    if (!conversation || conversation.error) return setError()
    props.navigation.setParams({ id: conversation.conversation_id })
    return conversation.conversation_id
  }

  const sendMessage = async (body, attachments) => {
		const hasConversationFeature = user?.features?.includes(Features.START_CONVERSATION) ?? false

		const isSprogland = Boolean(conversation?.sl_session_id || props.route.params.slSessionId)
		const isSnakSammen = Boolean(conversation?.vs_session_id || props.route.params.vsSessionId)
		const isPartner = isSprogland || isSnakSammen
		const suspendedDays = user.messagesSuspendedDays

		if (suspendedDays !== null && suspendedDays >= 0 && hasConversationFeature === false && isPartner === false) {
			setMessageSuspendedDialogText(() => {
				if (suspendedDays === 0) return txt('conversation.messageSuspensionToday')
				if (suspendedDays === 1) return txt('conversation.messageSuspensionTomorrow')
				if (suspendedDays > 1) return txt('conversation.messageSuspensionInXDays', { days: suspendedDays })
			})
			setMessageSuspendedDialogVisible(true)
			return false
		}

    if (body.trim() === '') return false

    if (sendMessageNotAllowed()) {
      setShowNoAccessDialog(true)
      return false
    }

    const consent = await getScreeningConsent()

    if (consent === 0) {
      setShowConsentDialog(true)
      return false
    }

    let conversationId = props.route.params.id

    if (conversationId === 'new') {
      conversationId = await createNewConversation()
    }

    if (!conversationId) return false

    const message = { body, attachments }
    const response = await InboxService.createMessage(message, conversationId)

    if (response.error === 'ACCEPT_SCREENING') {
      setShowConsentDialog(true)
      return false
    }

    return true
  }

  function sendMessageNotAllowed() {
    if (user.country !== 'Denmark') return false
    const isPostConversation = !!conversation?.post_id
    const noConversationFeature = !user.features.includes(Features.START_CONVERSATION)
    const sameMunicipality = user.municipality_id === conversation?.otherUsers[0]?.municipalityId
    return isPostConversation && sameMunicipality && noConversationFeature && !hasFeature
  }

  const handleOptionPress = (currentMessage) => {
    setShowMessageOptions(true)
    setSelectedMessage(currentMessage)
  }

  function validateText(text) {
    const unacceptedTextMatcher = new RegExp(unacceptedConversationText.join('|'), 'gim')
    const matchResult = unacceptedTextMatcher.exec(text)

    if (matchResult === null) {
      return setUnacceptedText('')
    }

    setUnacceptedText(matchResult[0])
    Keyboard.dismiss()

    if (Platform.OS === 'web') {
      setShowTextValidateDialog(true)
    }
  }

  async function copyText() {
    const copied = await Clipboard.setStringAsync(selectedMessage.text);
    if (copied) {
      setTextCopied(true)
      setTimeout(() => setTextCopied(false), 1500)
    }
  }

  const getShowAttachmentButton = () => {
	if (conversation?.sl_session_id) return true
	if (conversation?.groupAdminConversation) return true
	return false
  }

	const openSubscription = async () => {
		await handlePaymentClick()
		setMessageSuspendedDialogVisible(false)
	}

  return (
		<>
			{conversation && conversation.otherUsers.length > 0 && (
				<Connection users={conversation?.otherUsers} connection={connection} setConnection={setConnection} />
			)}
			<View style={styles.containerStyle}>
				<Chat
					route={props.route}
					accessible
					user={user}
					messages={messages}
					onLongPress={(context, message) => onMessageLongPress(context, message)}
					optionsButton={(props) => (
						<OptionButton onPress={() => handleOptionPress(props.currentMessage)} size={Fontsizes.l} vertical />
					)}
					onPressAvatar={(item) => handleOnPressAvatar(item)}
					onSendMessage={sendMessage}
					validateText={validateText}
					showAttachmentButton={getShowAttachmentButton()}
				/>

				<Portal>
					<OptionsDialog
						visible={showMessageOptions}
						onDismiss={() => setShowMessageOptions(false)}
						options={options}
					/>
					<OptionsDialog visible={showCopyOption} onDismiss={() => setShowCopyOption(false)} options={copyOptions} />
					<OptionsDialog
						visible={showConfirmDeletion}
						onDismiss={() => setShowConfirmDeletion(false)}
						options={optionsConfirmDelete}
					/>
					<ScreeningConsentDialog visible={showConsentDialog} handleHideDialog={() => setShowConsentDialog(false)} />
				</Portal>
				<CustomDialog visible={showTooManyConvDialog} onDismiss={() => setShowTooManyConvDialog(false)}>
					<ContentContainer>
						<CustomText>{txt('conversations.tooMany')}</CustomText>
					</ContentContainer>
					<View style={{ paddingBottom: 24 }}>
						<ButtonPrimary
							onPress={() => setShowTooManyConvDialog(false)}
							style={{ backgroundColor: Colors.blueAzure }}
							title={txt('global.ok')}
							titleStyle={{ paddingHorizontal: 12 }}
						/>
					</View>
				</CustomDialog>
				{Platform.OS === 'ios' && <KeyboardSpacer topSpacing={-48.5} />}

				<CustomDialog visible={showTextValidateDialog}>
					<ContentContainer>
						<CustomText font="bold" style={styles.textCenter} text={txt('conversation.unacceptedTextTitle')} />
						<CustomText style={[styles.textCenter, styles.textMargin]} text={txt('conversation.unacceptedTextInfo1')} />
						<CustomText style={[styles.textCenter, styles.textMargin, styles.textRed]}>{unacceptedText}</CustomText>
						<CustomText style={[styles.textCenter, styles.textMargin]} text={txt('conversation.unacceptedTextInfo2')} />
						<CustomText style={[styles.textCenter, styles.textMargin]} text={txt('conversation.unacceptedTextInfo3')} />
					</ContentContainer>
					<ActionButton onPress={() => setShowTextValidateDialog(false)}>{txt('dialog.ok')}</ActionButton>
				</CustomDialog>

				<CustomDialog
					visible={messageSuspendedDialogVisible}
					onDismiss={() => setMessageSuspendedDialogVisible(false)}
				>
					<ContentContainer>
						<CustomPressable style={styles.closeIcon} accessibilityRole='button' onPress={() => setMessageSuspendedDialogVisible(false)}>
							<NormalIcon size={22} name="times" color={Colors.inactive} />
						</CustomPressable>
						<CustomText font="largeBold" style={{paddingRight: 25}}>{txt('conversation.messageSuspensionTitle')}</CustomText>
						<CustomText style={{ marginTop: 12 }}>{messageSuspendedDialogText}</CustomText>
						<CustomText style={{ marginTop: 12 }}>{txt('conversation.messageSuspensionSubscriptionInfomation')}</CustomText>

						<View style={[styles.points, {marginTop: 18}]}>
							<View style={styles.point}>
								<NormalIcon name="check" style={styles.pointIcon} size={14} color={Colors.greenIcon} />
								<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point1')}</CustomText>
							</View>

							<View style={styles.point}>
								<NormalIcon name="check" style={styles.pointIcon} size={14} color={Colors.greenIcon} />
								<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point2')}</CustomText>
							</View>

							<View style={styles.point}>
								<NormalIcon name="check" style={styles.pointIcon} size={14} color={Colors.greenIcon} />
								<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point3')}</CustomText>
							</View>

							<View style={styles.point}>
								<NormalIcon name="check" style={styles.pointIcon} size={14} color={Colors.greenIcon} />
								<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point4')}</CustomText>
							</View>

							<View style={styles.point}>
								<NormalIcon name="check" style={styles.pointIcon} size={14} color={Colors.greenIcon} />
								<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point5')}</CustomText>
							</View>

							<View style={styles.point}>
								<NormalIcon name="check" style={styles.pointIcon} size={14} color={Colors.greenIcon} />
								<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point6')}</CustomText>
							</View>
						</View>
					</ContentContainer>
					<ButtonPrimary
						onPress={openSubscription}
						style={{ backgroundColor: Colors.greenBtn, marginBottom: 24 }}
						title={txt('subscription.dialogs.subscriptionDialog.button')}
						titleStyle={{ paddingHorizontal: 12 }}
					/>
				</CustomDialog>

				{conversation && (
					<ContactUnavailableDialog
						visible={showNoAccessDialog}
						hide={() => setShowNoAccessDialog(false)}
						username={conversation?.otherUsers?.[0]?.username}
					/>
				)}
				<Modal animationType="fade" transparent visible={textCopied} onRequestClose={() => setTextCopied(false)}>
					<CustomPressable
						accessibilityRole="none"
						onPress={() => setTextCopied(false)}
						style={styles.confirmationContainer}
					>
						<TouchableWithoutFeedback accessibilityRole="none">
							<View style={styles.confirmation}>
								<NormalIcon name="check" stroke="fad" color={Colors.greenIcon} size={40} />
								<CustomText font="smallBold" style={{ paddingTop: 18 }}>
									{txt('conversations.messageOptionsCopyConfirmed')}
								</CustomText>
							</View>
						</TouchableWithoutFeedback>
					</CustomPressable>
				</Modal>
			</View>
		</>
	)
}

const styles = StyleSheet.create({
	containerStyle: {
		marginLeft: 10,
		marginRight: 10,
		...SpacingStyles.widthAndHeight,
		flex: 1,
	},
	title: { textAlign: 'center', width: '75%' },
	textCenter: { textAlign: 'center' },
	textRed: { color: Colors.errorBackground },
	textMargin: { marginVertical: 6 },
	confirmationContainer: {
		flex: 1,
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: Colors.blackTransparent54,
	},
	confirmation: {
		backgroundColor: Colors.whiteTransparent,
		padding: 24,
		borderRadius: 10,
		alignItems: 'center',
	},

	points: {
		gap: 8,
		marginLeft: 6,
		marginTop: 18,
	},

	point: {
		flexDirection: 'row',
		gap: 8,
	},

	pointIcon: {
		padding: 3,
		backgroundColor: Colors.greenExtraLight,
		borderRadius: 10,
	},

	pointText: {
		color: Colors.darkGrey,
	},

	closeIcon: {
		alignSelf: 'flex-end',
		position: 'absolute',
		paddingLeft: 24,
		zIndex: 1,
	},
})
