import { universes } from './universeConfig'

export function isUniverse(name) {
  const universe = universes.find(
    (universe) => universe.name === name?.toLowerCase()
  )
  return !!universe
}

export function getUniverseId(name) {
  const universe = universes.find(
    (universe) => universe.name === name?.toLowerCase()
  )
  return universe?.id
}

export function getUniverseConfig(name) {
  const universe = universes.find(
    (universe) => universe.name === name?.toLowerCase()
  )
  return universe
}

export function getUniverseConfigById(id) {
  const universe = universes.find((universe) => universe.id === id)
  return universe
}


/**
 * This function is used to reliably extract the 'universe' identifier from the route object.
 *
 * The 'universe' determines what content or features should be displayed.
 * This identifier can be passed in different ways depending on the navigation path taken:
 *
 * 1. **route.params**: The 'universe' is often passed as a parameter in the route's params object.
 * 2. **Nested Navigation States**: When navigating through nested stacks or screens (or reloaded pages), the 'universe'
 *    may be buried deeper within a child navigation state (represented by Symbol(CHILD_STATE)).
 * 3. **URL Path**: For direct links or reloaded pages, the 'universe' may be embedded in the URL path,
 *    typically following the '/u/' segment.
 *
 * This function handles all these cases by:
 * - First checking for 'universe' in the route's params.
 * - Then recursively searching through nested route objects within Symbol(CHILD_STATE).
 * - Finally, if 'universe' is not found in params or nested routes, the function parses the URL
 *   path to extract the 'universe' from the segment following '/u/'.
 *
 * This comprehensive search ensures that the app can correctly determine the current 'universe'
 * regardless of how the user navigated to the current screen, whether through normal navigation,
 * a deep link, or a page reload.
 */
export function extractUniverseFromRoute(route) {
  // Check if the route has a params object inside a params object with the universe key
  const findUniverseInParams = (params) => {
    if (!params) return null
    if (params.universe) return params.universe.toLowerCase()
    return findUniverseInParams(params.params) // Drill down if nested params exist
  }

  // Try to find the universe in the params directly
  const universeFromParams = findUniverseInParams(route.params)
  if (universeFromParams) return universeFromParams

  // Handle the Symbol(CHILD_STATE) nested structure
  const childState = route[Object.getOwnPropertySymbols(route).find(sym => sym.toString() === 'Symbol(CHILD_STATE)')]
  if (childState && childState.routes && Array.isArray(childState.routes)) {
    for (let nestedRoute of childState.routes) {
      // Check if the nested route has a state property and then drill down
      if (nestedRoute.state && nestedRoute.state.routes && Array.isArray(nestedRoute.state.routes)) {
        for (let deepRoute of nestedRoute.state.routes) {
          const foundUniverse = extractUniverseFromRoute(deepRoute)
          if (foundUniverse) return foundUniverse
        }
      } else {
        const foundUniverse = extractUniverseFromRoute(nestedRoute)
        if (foundUniverse) return foundUniverse
      }
    }
  }

  // If universe is not found in params or nested routes, check the path
  if (route.path) {
    const pathSegments = route.path.split('/')
    const universeIndex = pathSegments.indexOf('u') + 1
    if (universeIndex > 0 && pathSegments[universeIndex]) {
      return pathSegments[universeIndex].toLowerCase()
    }
  }

  return null
}