// libraries
import React, { useEffect, useState } from 'react'
import { Animated, FlatList, Keyboard, Platform, StyleSheet, View } from 'react-native'
import { useRoute, useNavigation } from '@react-navigation/native'

// components
import LoadingSpinner from '../activityIndicators/LoadingSpinner'
import { SearchBarCustom, SearchBarWeb } from '../text/StyledTextinputs'
import { CustomText } from '../text/StyledText'

// context
import { txt } from '../../locales/i18n'
import { SpacingStyles } from '../../styles'
import Colors from '../../constants/Colors'
import { badWords } from '../../constants/BadWords'
import store from '../../redux/store'
import { saveSearchString } from '../../services/api/Posts'
import CustomDialog, { ActionButton, ContentContainer } from '../../components/dialogs/CustomDialog'

class SearchList extends React.Component {
  constructor(props) {
    super(props)

    // default paginate value if props.paginate isn't defined
    this.paginate = props.paginate ?? 20

    this.state = {
      data: [],
      searchText: props.route?.params?.search || '',
      isLoading: false,
      canFetchData: true,
      catchedWord: '',
      showWordModal: false,
      showHeader: true,
      refreshing: false,
      headerHeight: 100,
    }

    this.scrolling = this.props.isScrolling

    this.timeout = 0
    this.canFetchData = true

    this.headerY = new Animated.Value(0)
    this.offset = 0
    this.offSetTimeout = 0

    this.isScrolling = false
    this.isAnimating = false
  }

  getHeight(event) {
    const height = event.nativeEvent.layout.height
    this.setState({ headerHeight: height })
  }

  async componentDidMount() {
    // await this.getData()
    await this.getData()
  }

  async componentDidUpdate(prevProps) {
    if (this.props.isFocussed && prevProps.isFocussed === false) {
      await this.getData()
    }

    if (this.state.showHeader) {
      Animated.timing(this.headerY, {
        toValue: 0,
        duration: 250,
        useNativeDriver: false,
      }).start()
    } else {
      Animated.timing(this.headerY, {
        toValue: -this.state.headerHeight - 6,
        duration: 250,
        useNativeDriver: false,
      }).start()
    }

    const state = this.props.triggerUpdate?.()
    if (state) {
      this.setState(
        {
          data: [],
          isLoading: true,
          canFetchData: true,
        },
        this.getData
      )
    }
    if (this.props.childFunc) this.props.childFunc.current = this.handleRefresh
  }

  getDataLength() {
    const filteredData = this.state.data.filter((item) => !item.noCount)
    return filteredData.length
  }

  handleChangeText = async (input) => {
    clearTimeout(this.timeout)

    const isBadWord = await this.checkForBadWord(input)

    this.setState({
      data: [],
      searchText: input,
      isLoading: true,
      showWordModal: isBadWord,
    })
    this.props.navigation.setParams({ search: input })

    this.canFetchData = true

    this.timeout = setTimeout(async () => {
      const dataResponse = await this.props.getData({
        search: input,
        skip: 0,
        amount: this.paginate,
        isSearch: true,
      })

      this.setState({ data: dataResponse, isLoading: false })
    }, 800)
  }

  clearSearch = async () => {
    this.canFetchData = true
    this.setState({ data: [], isLoading: true, searchText: '' })
    this.props.navigation.setParams({ search: undefined })
    const dataResponse = await this.props.getData({
      search: '',
      skip: 0,
      amount: this.paginate,
      isSearch: true,
    })
    this.setState({ data: dataResponse, isLoading: false })
  }

  checkForBadWord = async (search) => {
    this.setState({ catchedWord: search })
    return !!badWords.includes(search.toLowerCase())
  }

  getData = async () => {
    this.setState({ isLoading: true })

    this.canFetchData = true
    const dataResponse = await this.props.getData({
      search: this.state.searchText,
      skip: this.getDataLength(),
      amount: this.paginate,
      isSearch: false,
    })

    if (!dataResponse) {
      return this.setState({
        isLoading: false,
        refreshing: false,
      })
    }

    this.setState({
      data: this.state.data.concat(dataResponse ?? []),
      isLoading: false,
      refreshing: false,
    })

    this.canFetchData = dataResponse?.length !== 0
  }

  handleEndReached = (event) => {
    const atScrollEnd = event.distanceFromEnd >= -500

    if (atScrollEnd && !this.state.isLoading && this.canFetchData) {
      this.getData()
    }
  }

  handleSearchBlur = () => {
    const searchString = this.state.searchText
    const userId = store.getState().user?.id
    if (searchString && userId) {
      if (this.props.saveSearch) {
        this.props.saveSearch()
      } else {
        saveSearchString({ searchString })
      }
    }
  }

  handleRefresh = async () => {
    this.setState({
      refreshing: true,
      data: [],
      isLoading: true,
      searchText: '',
    })
    await this.props.refresh?.()

    const dataResponse = await this.props.getData({
      search: '',
      skip: 0,
      amount: this.paginate,
      isSearch: false,
    })

    this.setState({
      data: dataResponse,
      isLoading: false,
      refreshing: false,
    })
  }

  renderSearchbar = () => {
    if (Platform.OS === 'web') {
      return (
        <SearchBarWeb
          onBlur={this.handleSearchBlur}
          value={this.state.searchText}
          onChangeText={this.handleChangeText}
          clearText={this.clearSearch}
          accessibilityLabel={txt('searchField.placeHolder')}
          accessibilityHint={txt('searchField.hint')}
        />
      )
    } else {
      return (
        <SearchBarCustom
          onBlur={this.handleSearchBlur}
          value={this.state.searchText}
          onChangeText={this.handleChangeText}
          onClear={this.clearSearch}
        />
      )
    }
  }

  handleOffset = (currentOffset) => {
    if (Platform.OS === 'web') {
      clearTimeout(this.offSetTimeout)

      if (currentOffset === 0) this.setState({ showHeader: true })

      if (currentOffset > this.offset && this.state.showHeader) {
        this.setState({ showHeader: false })
      }
      if (currentOffset < this.offset && !this.state.showHeader) {
        this.setState({ showHeader: true })
      }
      this.offSetTimeout = setTimeout(() => {
        this.offset = currentOffset
      }, 0)
    } else {
      if (currentOffset <= 0) this.setState({ showHeader: true })
      if (this.isScrolling) {
        if (currentOffset > this.offset && this.state.showHeader) {
          this.setState({ showHeader: false })
        }
        if (currentOffset < this.offset && !this.state.showHeader) {
          this.setState({ showHeader: true })
        }
      } else {
        this.offset = currentOffset
      }
    }
  }

  renderEmptySearchResult() {
    const notLoading = !this.state.isLoading
    const emptySearch = this.state.searchText === ''

    if (notLoading && emptySearch) {
      return (
        <>
          <CustomText style={styles.noAccessText}>{txt('noResults.noSearchResults')}</CustomText>
          {!this.props.christmas && <CustomText style={styles.noAccessText}>{txt('noResults.noAccess')}</CustomText>}
        </>
      )
    } else if (notLoading) {
      return (
        <>
          <CustomText style={styles.noAccessText}>{txt('noResults.noSearchResults')}</CustomText>
        </>
      )
    }
  }

  render() {
    const { backgroundColor = Colors.skyBlue } = this.props

    return (
      <View style={[styles.container, { alignSelf: this.props.center ? 'center' : null }, { ...this.props.style }]}>
        <CustomDialog visible={this.state.showWordModal}>
          <ContentContainer>
            <CustomText font="bold" style={styles.textCenter} text={txt('home.badWord1') + '\n'} />
            <CustomText style={styles.textCenter} text={txt('home.badWord2') + '\n'} />
            <CustomText style={[styles.textCenter, styles.textRed]}>{this.state.catchedWord}</CustomText>
            <CustomText style={styles.textCenter} text={txt('home.badWord3') + '\n'} />
          </ContentContainer>
          <ActionButton onPress={() => this.setState({ showWordModal: false })}>{txt('dialog.ok')}</ActionButton>
        </CustomDialog>
        <Animated.View
          style={[styles.animatedHeader, { transform: [{ translateY: this.headerY }], backgroundColor }]}
          onLayout={(event) => this.getHeight(event)}
        >
          {this.props.renderChips?.()}
          {this.props.filterAbove?.()}
          <View style={styles.searchbarContainer}>
            {this.renderSearchbar()}
            {this.props.filterRight?.()}
          </View>
          {this.props.filterBelow?.()}
        </Animated.View>
        <FlatList
          contentContainerStyle={{
            paddingTop: this.state.headerHeight,
            paddingBottom: 12,
          }}
          data={this.props?.applyAdvertisements?.(this.state.data) ?? this.state.data}
          renderItem={this.props.renderItem}
          onEndReached={this.handleEndReached}
          scrollEventThrottle={16}
          onScroll={(e) => {
            this.handleOffset(e.nativeEvent.contentOffset.y)
            if (Platform.OS !== 'web') return

            if (!this.state.isLoading) {
              Keyboard.dismiss()
              if (this.scrolling) this.scrolling(true)
            }
          }}
          onScrollBeginDrag={() => {
            this.isScrolling = true
          }}
          onScrollEndDrag={() => (this.isScrolling = false)}
          ListEmptyComponent={this.renderEmptySearchResult()}
          ListFooterComponent={this.state.isLoading && <LoadingSpinner />}
          maxToRenderPerBatch={this.paginate * 2}
          onEndReachedThreshold={2}
          keyExtractor={(item, index) => index.toString()}
          onRefresh={Platform.OS === 'web' ? undefined : this.handleRefresh}
          refreshing={this.state.refreshing}
          {...this.props}
          onMomentumScrollBegin={() => {
            if (!this.state.isLoading) {
              Keyboard.dismiss()
              if (this.scrolling) this.scrolling(true)
            }
          }}
        />
      </View>
    )
  }
}

export default function (props) {
  const route = useRoute()
  const navigation = useNavigation()
  const [isFocussed, setIsFocussed] = useState(true)

  useEffect(() => {
    const unsubscribeFocus = navigation.addListener('focus', () => setIsFocussed(true))
    const unsubscribeBlur = navigation.addListener('blur', () => setIsFocussed(false))

    return () => {
      unsubscribeFocus()
      unsubscribeBlur()
    }
  }, [])

  return <SearchList {...props} route={route} navigation={navigation} isFocussed={isFocussed} />
}

export function ListSeparator({ text, color = null }) {
  return (
    <View style={styles.separator}>
      <View style={[styles.separatorLine, color && { backgroundColor: color }]} />
      <CustomText font="smallBold" style={styles.separatorText}>
        {text.toUpperCase()}
      </CustomText>
      <View style={[styles.separatorLine, color && { backgroundColor: color }]} />
    </View>
  )
}

const styles = StyleSheet.create({
  animatedHeader: {
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1,
  },
  container: {
    flex: 1,
    ...SpacingStyles.widthAndHeight,
  },
  noAccessText: {
    color: Colors.inactive,
    marginLeft: 12,
    marginTop: 12,
    paddingTop: 10,
    textAlign: 'center',
  },

  searchbarContainer: {
    marginHorizontal: 12,
    marginBottom: 6,
    marginTop: 6,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  separator: {
    alignItems: 'center',
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: 12,
    marginTop: 6,
  },
  separatorLine: {
    backgroundColor: Colors.inactive,
    flex: 1,
    height: 1,
    marginLeft: 12,
    marginRight: 12,
  },
  separatorText: {
    textAlign: 'center',
  },
  textCenter: { textAlign: 'center' },
  textRed: { color: Colors.errorBackground },
})
