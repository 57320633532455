import React, { useEffect, useState } from 'react'
import { Platform, StatusBar, StyleSheet, View } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Provider } from 'react-redux'
import { Helmet } from 'react-helmet'
import * as Font from 'expo-font'

import Navigator from './navigation/AppNavigator'
import Colors from './constants/Colors'
// import './patches/SocketIOPatches'
import i18n from './locales/i18n'

import AppAnnouncement from './components/snackbars/AppAnnouncement'
import MakeInfluence from './components/Integrations/MakeInfluence'
import { Provider as PaperProvider } from 'react-native-paper'
import store from './redux/store'
import { postLoginAction, getToken } from './services/authActions'
import { NotificationsBadgeListener } from './services/notifications'
import DownloadAppBanner from './components/overlay/DownloadAppBanner'

import ignoreErrors from './utils/ignoreErrors'
import AddIconLibrary from './constants/Icons'
import GlobalDialog from './components/dialogs/GlobalDialog'
import ChooseGenderDialog from './components/overlay/ChooseGenderDialog'
import { getDomainLanguage, getLanguage } from './services/localization'
import SubscriptionDialog from './components/dialogs/SubscriptionDialog'
import { UpdateDialog } from './components/dialogs/update'

import { jotaiStore } from './atoms'
import { Provider as JotaiProvider } from 'jotai'

ignoreErrors()

import * as Sentry from './services/sentry'

export default Sentry.wrap((props) => {
	const [loading, setLoading] = useState(true)
	const [fontsLoaded] = Font.useFonts({
		'raleway-regular': require('./assets/fonts/Raleway-Regular.ttf'),
		'raleway-bold': require('./assets/fonts/Raleway-Bold.ttf'),
		'raleway-semibold': require('./assets/fonts/Raleway-SemiBold.ttf'),
		'raleway-italic': require('./assets/fonts/Raleway-Italic.ttf'),
		'oswald-bold': require('./assets/fonts/Oswald-Bold.ttf'),
		'oswald-semibold': require('./assets/fonts/Oswald-SemiBold.ttf'),
		'gotham-book': require('./assets/fonts/Gotham-Book.otf'),
		'gotham-bold': require('./assets/fonts/Gotham-Bold.otf'),
	})

	useEffect(() => {
		loadAppAsync().then(() => setLoading(false))
	}, [])

	if (fontsLoaded === false || (loading && !props.skipLoadingScreen)) {
		return <View />
	}

	return (
		<View style={styles.container}>
			<StatusBar barStyle={'dark-content'} backgroundColor={Colors.background} />
			{Platform.OS === 'web' && (
				<Helmet>
					<script
						id="CookieConsent"
						src="https://policy.app.cookieinformation.com/uc.js"
						data-culture={getDomainLanguage()?.toUpperCase() ?? 'DA'}
						type="text/javascript"
					/>
					<meta name="apple-itunes-app" content="app-id=1530812655" />
				</Helmet>
			)}

			<Provider store={store}>
				<JotaiProvider store={jotaiStore}>
					<PaperProvider>
						<Navigator />
						<MakeInfluence />
						<DownloadAppBanner />
						<ChooseGenderDialog />
						<SubscriptionDialog />
						<AppAnnouncement />
						<GlobalDialog />
						<UpdateDialog />
					</PaperProvider>
					<NotificationsBadgeListener />
				</JotaiProvider>
			</Provider>
		</View>
	)
})

async function loadAppAsync() {
	AddIconLibrary()
	const token = Platform.OS === 'web' ? getToken() : await AsyncStorage.getItem('userToken')
	if (token) await postLoginAction(token)

	const language = await getLanguage()

	await AsyncStorage.setItem('language', language)
	i18n.locale = language
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: Colors.background,
		flex: 1,
	},
})
