import React, { useState, useEffect, useRef } from 'react'
import { StyleSheet, Platform, View } from 'react-native'
import OptionsList from '../../../components/lists/OptionsList'
import { txt } from '../../../locales/i18n'
import TxtTMD from '../../../constants/TxtTMD'
import PageTitle from '../../../components/tags/PageTitle'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { getUniverseConfig } from '../../../universe/universeController'
import { useSelector } from 'react-redux'
import { Modules } from '../../../constants/Modules'
import { joinUniverseById } from '../../../services/api/Universe'
import layout from '../../../constants/Layout'
import { URLOpener } from '../../../utils/URLOpener'
import Paths from '../../../constants/Paths'

export default function StackUniverseMenuScreen(props) {
  const [universe, setUniverse] = useState('')
  const [loading, setLoading] = useState(true)
  const [menuOptions, setMenuOptions] = useState([])
  const country = useSelector((state) => state.user.country)	
  const universes = useSelector((state) => state.user?.universes)
  const universeObject = getUniverseConfig(props.route.params.universe?.toLowerCase())
  const data = useRef([]).current

  useEffect(() => {
    const universeName = props.route.params.universe?.toLowerCase()
    const universe = universes.find((uni) => uni.name === universeName)
    if (universe) {
      setUniverse(universe)
    } else {
      props.navigation.navigate('app')
    }
  }, [])

  useEffect(() => {
    if (universe && loading) {
      setLoading(false)
    }
    if (!loading) {
      generateMenu()
    }
  }, [universe, loading])

  function generateMenu() {
    data.push(
      {
        body: txt('stackMenu.stories'),
        onPress: () => {
          props.navigation.navigate('Stories', {
            universe: universe.name,
          })
        },
        icon: {
          name: 'book-open',
        },
      },
      {
        body: txt('profile.title'),
        onPress: () => {
          props.navigation.navigate('Profile', {
            universe: universe.name,
          })
        },
        icon: {
          name: 'user',
        },
      },
      {
        body: txt('settings.title'),
        onPress: () => {
          props.navigation.navigate('Settings', {
            universe: universe.name,
          })
        },
        icon: {
          name: 'cog',
        },
      }
    )
    if (['aauucn', 'au', 'herlev', 'krifa'].includes(universe.name)) {
      data.splice(data.length - 2, 0, {
        body: txt('events.title'),
        onPress: () => {
          props.navigation.navigate('Events', {
            universe: universe.name,
          })
        },
        icon: {
          name: 'calendar-star',
        },
      })
    }
    if (universe.modules?.includes(Modules.GROUPS)) {
      data.splice(data.length - 2, 0, {
        body: txt('groups.title'),
        onPress: () => {
          props.navigation.navigate('Groups', {
            universe: universe.name,
          })
        },
        icon: {
          name: 'users',
        },
      })
    }

    if (Platform.OS === 'web') {
      const cookieButton = {
        body: txt('cookieLabel'),
        onPress: () => showCookieBanner(),
        icon: {
          name: 'cookie-bite',
        },
      }

      // adds cookie button to second last position
      data.splice(data.length - 2, 0, cookieButton)
    }

    universes?.forEach((universe) => {
      if (universe.name === 'nytaarsvenner') return
      if (universe.name !== universeObject?.name) {
        const universeData = {
          body: txt('boblberg.goTo') + universe.title,
          onPress: () => {
            props.navigation.push('universe', {
              screen: 'UniverseBoblStack',
              params: {
                universe: universe.name,
              },
            })
          },
          icon: {
            name: 'bubbles',
            boblbergIcon: true,
          },
        }
        data.unshift(universeData)
      }
    })

    if (universeObject?.name === 'nytaarsvenner') {
      data.splice(data.length - (Platform.OS === 'web' ? 3 : 2 ), 0, {
        body: txt('universe.newyear.partnerships'),
        onPress: () => URLOpener(Paths.getBaseUrl() + 'nytaarsvenner/partnerskaber'),
        icon: { name: 'handshake-alt' },
      })
    }

    if (universeObject?.name === 'herlev') {
      const partnersButton = {
        body: txt('universe.herlev.offers'),
        onPress: () => {
          props.navigation.navigate('HerlevOffers', {
            universe: universeObject.name,
          })
        },
        icon: {
          name: 'grip-vertical',
        },
      }

      const snaksammenButton = {
        body: txt('videoChat.title'),
        onPress: () => {
          props.navigation.navigate('app', {
            screen: 'MenuStack',
            params: {
              initial: false,
              screen: 'SnakSammen',
            },
          })
        },
        icon: {
          name: 'comments',
        },
      }
      data.splice(data.length - 3, 0, partnersButton)
      data.splice(data.length - 4, 0, snaksammenButton)
    }

    if (universeObject?.name !== 'nytaarsvenner' && country === 'Denmark') {
      data.unshift({
        body: txt('boblberg.goTo') + txt('stackMenu.nytaarsvenner'),
        onPress: async () => {
          const universe = universes.find((uni) => uni.name === 'nytaarsvenner')
          if (!universe) await joinUniverseById(10)
          props.navigation.push('universe', {
            screen: 'UniverseBoblStack',
            params: {
              screen: 'Universe',
              initial: true,
              params: {
                universe: 'nytaarsvenner',
              },
            },
          })
        },
        icon: {
          name: 'glass-cheers',
        },
      })
    }

    if (country === 'Denmark') {
      data.unshift({
        body: txt('christmasFriends.title'),
        onPress: () => {
          props.navigation.navigate('christmasFriends')
        },
        icon: {
          name: 'hat-santa',
        },
      })
    }

    data.unshift({
      body: txt('boblberg.goToBoblberg'),
      onPress: () => {
        props.navigation.navigate('app')
      },
      icon: {
        name: 'bobl',
        boblbergIcon: true,
      },
    })
    setMenuOptions(data)
  }

  return (
    <MainContainer backgroundColor={universeObject?.backgroundColor}>
        <PageTitle title={TxtTMD.stackMenu.title[TxtTMD.lang]} />
        <View style={styles.container}>
          <OptionsList data={menuOptions} />
        </View>
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: layout.maxFeedWidth,
  },
})
