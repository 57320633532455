import { apiGet, apiPost, apiPut } from './ApiRequest'
import { postLoginAction, postLogoutAction, storeAccessToken } from '../authActions'
import store from '../../redux/store'
import {
  setUser,
  setUserPreferences,
  setUserToken,
  updateUserPreferences as updateUserPreferencesRedux,
} from '../../redux/actions'

// Login user and save token from api
export async function login(email, password) {
  if (!email || !password) return false
  try {
    let res = await apiPost({ email, password }, 'users/login')
    res = await res.json()
    if (!res.token) return false
    await storeAccessToken(res.token)
    await postLoginAction(res.token)
    return true
  } catch (err) {
    return false
  }
}

export async function signup({
  name,
  email,
  password,
  birthYear,
  municipalityId,
  language,
  token,
  origin,
  country,
}) {
  const data = {
    username: name.trim(),
    email: email.trim(),
    password: password,
    birth_year: birthYear,
    municipality_id: municipalityId,
    language,
    token,
    origin,
    country,
  }
  let res = await apiPost(data, 'users/signup')
  res = await res.json()
  return res
}

// Logout user from device
export async function logout() {
  await apiPost({},'users/logout')
  await postLogoutAction()
}

export async function getUserImage(userId) {
  const image = await apiGet(`users/image/${userId}`)
  if (!image) return
  return image
}

export async function getInvitedUser(token) {
  const res = await apiGet(`users/invite/${token}`)
  if (res.data) return res.data
  return null
}

export async function updateUser(userNew) {
  const userOld = store.getState().user
  const userUpdated = { ...userOld, ...userNew }

  if (userUpdated.image === null) {
    delete userUpdated.image
  }

  userUpdated.email = userUpdated.email.trim()
  userUpdated.username = userUpdated.username.trim()
  const res = await apiPut(userUpdated, 'users/update')
  if (!res || !res.ok || res.status >= 300) return false
  store.dispatch(setUser(userUpdated))
  return true
}

export async function changePassword(passwordOld, passwordNew) {
  const data = {
    passwordOld,
    passwordNew,
  }
  return apiPut(data, 'users/changePassword')
    .then((response) => response.json())
    .then((responseJson) => {
      if (!responseJson.data) {
        return false
      }
      if (__DEV__) console.log('responseJson')
      if (__DEV__) console.log(responseJson.data[0])
      const { userToken } = responseJson
      if (userToken) {
        store.dispatch(setUserToken(userToken))
        storeAccessToken(userToken)
      }
      return responseJson.data[0]
    })
    .catch((error) => {
      if (__DEV__) console.error(error)
      return false
    })
}

export async function changePasswordByToken(token, passwordNew) {
  const data = {
    token,
    passwordNew,
  }
  return apiPut(data, 'users/token/resetPassword')
    .then((response) => response.json())
    .then((responseJson) => {
      if (!responseJson.data) {
        return false
      }
      if (__DEV__) console.log('responseJson')
      if (__DEV__) console.log(responseJson.data[0])
      return responseJson.data[0]
    })
    .catch((error) => {
      if (__DEV__) console.error(error)
      return false
    })
}

export async function createPasswordToken(email) {
  const data = {
    email,
  }
  apiPost(data, 'users/token/resetPassword')
    .then((response) => response.json())
    .then((responseJson) => {
      if (!responseJson.data) {
        return
      }
    })
    .catch((error) => {
      if (__DEV__) console.error(error)
      return
    })
}

export async function getUser(user_id) {
  const res = await apiGet(`users/${user_id}`)
  return res.data
}

export async function getUsername(user_id) {
  const res = await apiGet(`users/username/${user_id}`)
  return res.data
}

export async function getOwnUser() {
  const res = await apiGet('users/getOwn')
  const user = res?.data
  if (user) store.dispatch(setUser(user))
  return user
}

export async function getUserCount() {
  const res = await apiGet('users/count')
  return res.data
}

export async function getUserPreferences() {
  const res = await apiGet('users/user/preferences')
  return res?.data
}

export async function updateUserPreferences(newPreferences) {
  const oldPreferences = store.getState().preferences
  store.dispatch(updateUserPreferencesRedux(newPreferences))
  try {
    const res = await apiPut(
      store.getState().preferences,
      'users/user/preferences'
    )
    const resJson = await res.json()
    return resJson?.data
  } catch (error) {
    store.dispatch(setUserPreferences(oldPreferences))
    return null
  }
}

export async function getSubscription() {
  let res = await apiGet('subscriptions')
  if (!res) return null
  if (res.data?.id) return await getCustomerSubscription(res.data.id)
  return null
}

export async function getCustomerSubscription(handle) {
  let res = await apiGet(`reepay/subscription/${handle}`)
  if (!res) return null
  return res
}

export async function paymentClick() {
  const res = await apiPost({}, 'users/paymentClick')
  if (!res) return null
  return res
}

export async function getHasFeatureOne(userId) {
  let res = await apiGet(`users/feature-one/${userId}`)
  if (!res) return false
  return res.hasFeatureOne
}

export async function generateUUID() {
  const res = await apiPost({}, 'users/uuid')
  if (!res) return null
  return await res.json()
}